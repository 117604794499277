<template>
  <!-- 礼物管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-input-search
                      placeholder="请输入分区名称"
                      v-model="queryParam.name"
                      @pressEnter="initTableData"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                </a-col>
                <a-col :md="24" :sm="24" style="margin-bottom: 20px">
                  <a-button style="border-radius: 2px" type="primary" @click="$refs.addForm.add()">新增</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" rowKey="id">
            <span slot="color" slot-scope="text, record">
              <a-tag :color="record.color">{{ record.color }}</a-tag>
            </span>
            <span slot="isFlashSale" slot-scope="text, record">
              <a-tag color="blue">{{ +record.isFlashSale === 1 ? '否' : '是' }}</a-tag>
            </span>

            <!-- 状态插槽 -->
            <span slot="delFlag" slot-scope="text, record">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch($event, record)"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <router-link :to="`/partitionitems?partitionId=${record.id}`">商品列表</router-link>
              <a-divider type="vertical" />
              <a-popconfirm lacement="topRight" title="确认删除？" @confirm="() => handleRemove(record)">
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <add-form ref="addForm" :areas="areas" @ok="initTableData"></add-form>
    <edit-form ref="editForm" :areas="areas" @ok="getList"></edit-form>
  </div>
</template>

<script>
import addForm from './addForm.vue'
import editForm from './editForm.vue'
import { partitionDelete, partitionPage } from '@/api/modular/mallLiving/partition'
import { areaPage } from '@/api/modular/mallLiving/area'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '名称',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '排序',
    align: 'center',
    dataIndex: 'sort',
    key: 'sort',
  },

  {
    title: '主题色',
    dataIndex: 'color',
    key: 'color',
    align: 'center',
    scopedSlots: { customRender: 'color' },
  },
  {
    title: '黑名单',
    dataIndex: 'blacklistAreaName',
    key: 'blacklistAreaName',
    align: 'center',
  },
  {
    title: '是否秒杀',
    dataIndex: 'isFlashSale',
    key: 'isFlashSale',
    align: 'center',
    scopedSlots: { customRender: 'isFlashSale' },
  },
  {
    title: '秒杀开始时间',
    dataIndex: 'flashSaleBegin',
    key: 'flashSaleBegin',
    align: 'center',
  },
  {
    title: '是否启用',
    dataIndex: 'delFlag',
    key: 'delFlag',
    align: 'center',
    scopedSlots: { customRender: 'delFlag' },
  },

  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: '240px',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      //代理商区域列表
      areas: [],
      data: [],
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {},
      recordItem: {},
    }
  },
  components: {
    addForm,
    editForm,
  },
  created() {
    this.changeSwitch = this.$bbo.debounce(this.changeSwitch, 200)
  },
  mounted() {
    this.initTableData()
    this.getAreas()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    getAreas() {
      areaPage({
        queryParam: {},
        page: {},
      }).then((res) => {
        console.info('areas', res)
        if (res.success && res.data) {
          this.areas = res.data.rows
        }
      })
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },

    changeSwitch(e, record) {
      let delFlag = e ? 1 : 3
      this.recordItem = record

      this.changeTypeStatus(delFlag)
    },
    handleRemove(record) {
      this.recordItem = record
      this.changeTypeStatus(2)
    },
    //切换状态函数（启用/禁用/删除）
    async changeTypeStatus(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await partitionDelete(params)
      console.log(data)
      const text = +delFlag === 1 ? '启用' : delFlag === 2 ? '删除' : '禁用'
      if (+data.code === 200) {
        this.getList()
        await this.$message.success('分区 ' + this.recordItem.name + ' 已' + text)
      }
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      partitionPage(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
